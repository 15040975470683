export const WORDS = [
  {'wordString':'durag','wordExplanation':'Durags are a critically important part of the Black hair care arsenal. Their purpose varies from helping to accelerate the development of waves (or swimmies), to keeping braided hair in place. A quote from writer Brian Josephs of GQ: "The durag’s stigma isn’t singular; black expression is susceptible to criminalization because it’s attached to a black body...One has to be ready to risk it all to be black and wavy."'},
  {'wordString':'urban','wordExplanation':'The dictionary definition of urban refers specifically to cities or city dwellers. In the 1970s the term "urban" was used by Black New York radio DJ Frankie Crocker- describing the music he played as "whats happening in the city" or "urban contemporary". Music industry terminology and US legislation like the Urban Renewal Program have made urban a practical, albeit incorrect, substitute for Black in the public\'s vocabulary.'},
  {'wordString':'bantu', 'wordExplanation':'A member of any of the African ethnic groups that speak a Bantu language. Also used to refer to the Bantu Knots hairstyle- worn iconically by Jada Pinkett Smith in the Matrix.'},
  {'wordString':'gumbo','wordExplanation':'"hol up wait a minute, lemme put some seasoning in this gumboo" - Jay Rock. Most scholars of food history are in agreement that gumbo has its origins in West Africa, though it is now most commonly associated with Southern Louisiana.'},
  {'wordString':'salty','wordExplanation':'Used in a sentence: "Yo, its got to be cuz Im seasoned, Haters give me them SALTY looks- Lawrys" - Kanye'},
  {'wordString':'trend','wordExplanation':'Black people (globally) set trends. As the Brooklyn comedy group Fools Circle says.."Pop Culture is Just Expired Black Culture."'},
  {'wordString':'fasho', 'wordExplanation':'An abbreviate version of "For Sure". "Will you play Black Wordle again tomorrow?".. "Fasho".'},
  {'wordString':'braid','wordExplanation':'Braids are both a protective style of wearing hair and a social expression of personal style. From showing social status or marital readiness to signifying a rejection of European beauty standards, braids have played critical roles in both ancient African cultures and more recent African-American culture.'},
  {'wordString':'black','wordExplanation':'For some, "Black" is  used as a racial category to encompass people of any African descent despite the incredible heterogeniety present within African people across the Diaspora. For others, "Black" gives undue credibility to a socially constructed concept used to oppress and exploit people of African descent on a global scale.  Read a great piece here: https://www.nytimes.com/2020/06/26/us/black-african-american-style-debate.html'},
  {'wordString':'finna', 'wordExplanation':'Colloquialism for “about to”'},
  {'wordString':'chile', 'wordExplanation':'Whew chilayy... This one is context and tone dependent, consult an expert.'},
  {'wordString':'alpha', 'wordExplanation':'The oldest, boldest, and coldest organization in the Divine Nine. WEB Du Bois, Martin Luther King Jr., Jesse Owens, Thurgood Marshall are all counted among their ranks.'},
  {'wordString':'edges','wordExplanation':'Shorter strands of hair that are positioned around the forehead and nape of neck. Typically styled with an edge control gel that allows the hairs to lay in a desired position, complementary to one’s hairstyle.'},
  {'wordString':'cowry', 'wordExplanation':'Cowry (or Cowrie) shells were used as a currency in parts of Africa, India, and China. In the Yoruba traditional religion of Ifa, cowry shells were also used in spiritual divination ceremonies. Today in many parts of the world cowry shells are still used as jewelry and ornaments.'},
  {'wordString':'drill', 'wordExplanation':'Chicago\'s #1 export. Drill has seeded derivates from Brooklyn to London and continues to influence mainstream music globally today.'},
  {'wordString':'cream', 'wordExplanation':'Cash Rules Everything Around Me. Wu-Tang Clan popularized the phrase with their 1994 single off of "Enter the Wu-Tang (36 Chambers)".'},
  {'wordString':'scarf', 'wordExplanation':'A tool to lay your edges, keep the hair down, etc.'},
  {'wordString':'shade','wordExplanation':'subtle & tasteful disrespect'},
  {'wordString':'twerk','wordExplanation':'A dance you might do in the club... or the mirror'},
  {'wordString':'petty','wordExplanation':'Childish behavior.. funny for observers though.'},
  {'wordString':'squad','wordExplanation':'A crew, group of people, gang, friends, homies, etc.'},
  {'wordString':'goofy','wordExplanation':'A clown. Used in a sentence: "Yo goofy ahh"'},
  {'wordString':'fleek','wordExplanation':'If something is on fleek, it\'s on point. Shotout to PeachesMonroee, the creator of the phrase in a 2014 vine video.'},
  {'wordString':'crunk','wordExplanation':'Lit! but in the early 2000s'},
  {'wordString':'power','wordExplanation':'Black Power, Power to the People... Power on STARZ'},
  {'wordString':'hyphy','wordExplanation':'What the youngsters get in East Oakland.'},
  {'wordString':'homie','wordExplanation':'A close friend'},
  {'wordString':'knuck', 'wordExplanation':'if you buck'},
  {'wordString':'bando', 'wordExplanation':'Marker of "urban blight". Often a place of enterprise used when systemic failures limit legitimate economic opportunities.'},
  {'wordString':'slide', 'wordExplanation':'To pull up, could be for any of a number of purposes. Consult an expert.'},
  {'wordString':'bogus','wordExplanation':'Fugaze, lame, fake, not valid.'},
  {'wordString':'juice', 'wordExplanation':'Clout, influence. 2Pac\'s best film.'},
  {'wordString':'hella', 'wordExplanation':'An adverb meaning "alot". West coast, standup.'},
  {'wordString':'henny', 'wordExplanation':'Liquor of choice for a certain segment of the population.'},
  {'wordString':'nappy', 'wordExplanation':'Black curly, kinky, hair. P.S. don\'t use this word if you\'re not Black. Thanks :)'},
  {'wordString':'turnt', 'wordExplanation':'High energy, hype or lit.. May or may not be substance assisted.'},
  {'wordString':'weave','wordExplanation':'Hair extensions or bundles.'},
  {'wordString':'joint', 'wordExplanation':'Rolled marijuana. Or a place. Or a person. Anything really. Like "jawn", but less versatile.'},
  {'wordString':'hotep','wordExplanation':'An Egyption wording meaning "to be satisfied or at peace". In modern culture it is often used to refer to alkaline water drinking, dashiki wearing, Kings & Queens of the Diaspora, led by the honorable Dr. Umar Johnson.'},
  {'wordString':'sauce', 'wordExplanation':'Like juice but it lasts longer.'},
  {'wordString':'twist', 'wordExplanation':'Natural hair style. Wear it up, down, in, or out.'},
  {'wordString':'unity', 'wordExplanation':'U.N.I.T.Y. Queen Latifah\'s womanist vision of a Black Utopia.'},
  {'wordString':'baddy', 'wordExplanation':'A good looking jawn.'},
  {'wordString':'slick', 'wordExplanation':'Could mean a lot, could mean a little, like low-key. I slick don\'t really know, depends on context.'},
  {'wordString':'queen', 'wordExplanation':'Black Women.'},
  {'wordString':'fried', 'wordExplanation':'If you get cooked then someone fried ya ass.'},
  {'wordString':'tribe', 'wordExplanation':'The people that have your back.'},
  {'wordString':'litty', 'wordExplanation':'A peak lit situation'},
  {'wordString':'krump', 'wordExplanation':'Dance originating in Los Angeles in the early 2000s- see documentary, Rize.'},
  {'wordString':'stush', 'wordExplanation':'Carribbean term used to refer to someone who is stuck up.'},
  {'wordString':'bunda', 'wordExplanation':'British yams.'},
  {'wordString':'stank', 'wordExplanation':'Negative adjective often used to describe unpleasant attitude or personality.'},
  {'wordString':'ganja', 'wordExplanation':'Tree, green, sticky, zaza, mary j, cannabis.. originally a Hindi word brought to Jamaica by Indian laborers.'},
  {'wordString':'switch', 'wordExplanation':'A suggestive walk or a tool used to discipline those bad ass kids.'},
  {'wordString':'grill', 'wordExplanation':'You wanna see my what?!'},
  {'wordString':'house', 'wordExplanation':'A dance/music style originating from Chicago house parties in the 1980s.'},
  {'wordString':'negro', 'wordExplanation':'Historical term for anyone with a degree of Black heritage.'},
  {'wordString':'aight', 'wordExplanation':'Affirmative.'},
  {'wordString':'tryna', 'wordExplanation':'You tryna support the Black people behind Black Wordle?'},
  {'wordString':'brick', 'wordExplanation':'When its hella cold in New York'},
  {'wordString':'blick', 'wordExplanation':'Another word for heater'},
  {'wordString':'omega', 'wordExplanation':'Historically Black fraternity founded at Howard- notable alumni include Carter G. Woodson, Langston Hughes, and Michael Jordan.'},
  {'wordString':'yerrr', 'wordExplanation':'Ayo!'},
  {'wordString':'gucci', 'wordExplanation':'When something is all good, its gucci. Unrelated, but shotout to Dapper Dan.'},
  {'wordString':'stick', 'wordExplanation':'Another word for the blick'},
  {'wordString':'wylin', 'wordExplanation':'New York slang for "you really bugging out right now"'},
  {'wordString':'tight', 'wordExplanation':'If someone has you tight- then you mad annoying'},
]



export const WORDS_PENDING = [
  { 'wordString':'enyce','wordExplanation':''},
  {'wordString':'mixed', 'wordExplanation':''},
]