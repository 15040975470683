import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word. Answers include some slang,
        but only the correct spelling will be accepted as valid, so incorrectly spelled slang
        likely won't count against you. If you are seeing a word for the second time, refresh the page.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="D"
          status="correct"
        />
        <Cell value="U" />
        <Cell value="R" />
        <Cell value="A" />
        <Cell value="G" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter D is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="A" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="T" />
        <Cell value="U" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter N is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell  value="F" />
        <Cell isRevealing={true} isCompleted={true} value="I" status="absent" />
        <Cell value="N" />
        <Cell value="N" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter I is not in the word in any spot.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        This project is brought to you by andcomputers, subscribe to our writing and other experiments. -{' '}
        <a
          href="https://andcomputers.io/signup?utm_source=stats-modal&utm_medium=stats-modal&utm_campaign=black-wordle"
          className="underline font-bold"
          target="_blank"
          rel="noreferrer"
        >
          subscribe here
        </a>{' '}
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        If you have suggestions for words to add, we'd love to hear from you.-{' '}
        <a
          href="https://airtable.com/shrhTZSkrXAzdnl5a"
          className="underline font-bold"
          target="_blank"
          rel="noreferrer"
        >
          submit a new word here
        </a>{' '}
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        This application is a fork of an open-source version of the word guessing game we all know and
        love. -{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
          target="_blank"
          rel="noreferrer"
        >
          check out the original code here
        </a>{' '}
      </p>

    </BaseModal>
  )
}
